import MainTitle from "components/Common/MainTitle";
import React from "react";
import { Fade } from "react-awesome-reveal";

const SectionServiceBottom = ({
  sectiondata,
  sectiondata2,
  whyChooseUs,
  clientBenefits,
  contactUs,
  calltoAction
}) => {
  return (
    <>
      <div className="product-content">
        <div className="container">
          {sectiondata2?.map((data) => {
            return (
              <>
                <MainTitle text={data?.ServiceMiddletitle} fontSize={"20"} />
                {/* <h3>{data?.ServiceMiddletitle}</h3> */}
                {data?.content?.map((contentdata) => {
                  return (
                    <>
                      {/* <p>
                       
                        <b>{contentdata?.contentTitle}</b>
                      </p> */}
                      <Fade direction="left" triggerOnce>
                        <MainTitle
                          text={contentdata?.contentTitle}
                          fontSize={"20"}
                        />
                      </Fade>
                      <Fade direction="up" triggerOnce>
                        <p>{contentdata?.content1}</p>
                      </Fade>
                      <Fade direction="up" triggerOnce>
                        <p>{contentdata?.content2}</p>
                      </Fade>
                      <Fade direction="up" triggerOnce>
                        <p>{contentdata?.content3}</p>
                      </Fade>
                    </>
                  );
                })}
              </>
            );
          })}
        </div>
      </div>
      {whyChooseUs?.length && (
        <div className="product-content">
          <div className="container">
            <Fade direction="left" triggerOnce>
              <MainTitle
                text={"Why Choose Us?"}
                position={"start"}
                width={"171px"}
              />
            </Fade>
            {whyChooseUs?.map((data) => {
              return (
                <>
                  <Fade direction="up" triggerOnce>
                    <h3>{data?.title}</h3>
                  </Fade>
                  <Fade direction="up" delay={100} triggerOnce>
                    <p>{data?.content}</p>
                  </Fade>
                  <Fade direction="up" delay={100} triggerOnce>
                    <p>{data?.content1}</p>
                  </Fade>
                  <Fade direction="up" delay={100} triggerOnce>
                    <p>{data?.content2}</p>
                  </Fade>
                </>
              );
            })}
          </div>
        </div>
      )}
      {clientBenefits?.length && (
        <div className="product-content">
          <div className="container">
            <MainTitle
              text={"Client Benefits"}
              position={"start"}
              width={"171px"}
            />
            {clientBenefits?.map((data) => {
              return (
                <>
                  <Fade direction="up" triggerOnce>
                    <p>{data?.content1}</p>
                  </Fade>
                  <Fade direction="up" triggerOnce>
                    <p>{data?.content2}</p>
                  </Fade>
                </>
              );
            })}
          </div>
        </div>
      )}

      <div className="product-content">
        <div className="container">
          <div className="row justify-content-center">
            {sectiondata?.map((data) => {
              return (
                <>
                  <div className="col-md-4">
                    {/* <h3>{data?.ServiceBottamtitle}</h3> */}
                    <Fade direction="up" triggerOnce>
                      <MainTitle
                        text={data?.ServiceBottamtitle}
                        fontSize={"20"}
                      />
                    </Fade>
                    <ul className="product-points">
                      {data?.content.map((content) => {
                        return (
                          <>
                            <Fade direction="up" triggerOnce delay={300}>
                              <li>{content?.content1}</li>
                            </Fade>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      {contactUs?.length && (
        <div className="product-content">
          <div className="container">
            <Fade direction="left" triggerOnce>
              <MainTitle
                text={"Contact Us"}
                position={"start"}
                width={"171px"}
              />
            </Fade>
            {contactUs?.map((data) => {
              return (
                <>
                  <Fade direction="up" triggerOnce>
                    <p>{data?.title}</p>
                  </Fade>
                  <Fade direction="up" delay={100} triggerOnce>
                    <p>{data?.content}</p>
                  </Fade>
                  <Fade direction="up" delay={100} triggerOnce>
                    <p>{data?.content1}</p>
                  </Fade>
                </>
              );
            })}
          </div>
        </div>
      )}
      {calltoAction?.length && (
        <div className="product-content">
          <div className="container">
            <Fade direction="left" triggerOnce>
              <MainTitle
                text={"Call to Action"}
                position={"start"}
                width={"171px"}
              />
            </Fade>
            {calltoAction?.map((data) => {
              return (
                <>
                  <Fade direction="up" triggerOnce>
                    <h3>{data?.title}</h3>
                  </Fade>
                  <Fade direction="up" delay={100} triggerOnce>
                    <p>{data?.content}</p>
                  </Fade>
                </>
              );
            })}
          </div>
        </div>
      )}
  
    </>
  );
};

export default SectionServiceBottom;
