import React from "react";
import { Fade } from "react-awesome-reveal";
// import AboutUsImg from '../../assets/images/AboutSouthAsiaConsultancy.webp'
// import MainTitle from '../Common/MainTitle'
// import LeftRedArrow from '../../assets/svg/LeftRedArrow'
import { Link } from "react-router-dom";
function SectionAboutUs() {
  return (
    <>
      <div className="about-class">
          <div className="row m-0">
            <div className="col-md-6 section-about-1 ">
              <div className="home-about-details">
                <Fade direction="up">
                  <h2 className="text-white"> About Us</h2>
                </Fade>
                <Fade direction="left">
                  <h2 className="large-para text-justify">
                  We are an emerging firm specializing in Comprehensive Business Advisory and Consultancy Services in India. Our mission is to provide optimum and reliable  solutions that empower businesses to thrive.
                  </h2>
                </Fade>
                <Fade direction="left" delay={200}>
                  {/* <p className="text-justify">
                    The firm is established in 1989, at Ahmedabad. It has more
                    than 3 decades experience of rendering services in the field
                    of Accounting, Auditing, Taxation, Corporate Advisory and
                    Foreign Trade Policy. The Experienced team of Abhisa is best
                    accounting firm in India provides specialised services of
                    corporate Income Tax, International Tax, High Networth
                    Individuals Succession plans, Business Establishments
                    Advisory, Tax Advisory Services In Ahmedabad, FEMA, NRI
                    Taxation, GST and foreign Direct Investment advisory to
                    multinational Corporates. The firm is having hands on
                    experience in establishing and providing compliance services
                    for “GIFTCITY” entity under IFSCA. The firm completed many
                    successful assignment of WOS/JV Company in India and outside
                    India.
                  </p> */}
                </Fade>
                <Fade direction="up">
                  <div>
                  <Link to='/about' className="explore-btn bg-white text-black ">Explore More</Link>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="col-md-6 section-about-2">
              <div className="about-knowledge">
                <Fade direction="up">
                  <h2>Our Expertise</h2>
                </Fade>
                <Fade direction="up">
                <h4 className="text-white text-justify">
                Our team is led by four seasoned experts, each with over 30 years of experience and a deep understanding of the Indian tax landscape, export-import regulations, and services related to Land Aquisition as well as Greenfield project setup in Gujarat. We offer unparalleled insights and strategies to help our clients navigate complex regulations and optimize their financial outcomes.
                </h4>
                </Fade>
                <Fade direction="up">
                  <div>
                    <button className="explore-btn bg-white text-black ">Explore More</button>
                  </div>
                </Fade>
              </div>
            </div>
          </div>

          {/* <div className='row'>
        <div className='col-md-6'>
      <img src={AboutUsImg} className='img-fluid' alt='aboutUS'/>
        </div>
        <div className='col-md-6'>
        <MainTitle text={'About Abhisa '} position={'start'} width={'80px'}/>
        <p className='SectionAboutText'>
        Abhisa   services have now expanded across a wide range to suit our client’s requirement. In a short span, have gained the confidence of Clients in India and Overseas. We are experts at delivering comprehensive business solutions, robust procedures, smooth operations, with QHSE having the highest priority with zero LTI.
        </p>
        <Link to="/about">
        <span className="s-primary d-flex align-items-center ">
        Read About SAC <LeftRedArrow />
                  </span>
      </Link>
        </div>
        </div> */}
        
      </div>
    </>
  );
}

export default SectionAboutUs;
