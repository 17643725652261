import React from "react";
import { Fade } from "react-awesome-reveal";

const SectionAboutBottom = () => {
  const AboutBottomData = [
    {
      title: "Our Expertise",
      content: [
        {
          subContent:
            "Our team is led by four seasoned experts, each with over 30 years of experience in the Greenfield project setup and in the field of direct and indirect taxation. With a deep understanding of the Indian tax landscape, Export Import Regulations, and services related to Greenfield Project setup in Gujarat, we offer unparalleled insights and strategies to help our clients navigate complex regulations and optimize their financial outcomes.",
        },
      ],
      gridSize: "col-md-6",
      fadeDirection: "up",
    },
    {
      title: "Our Values",
      content: [
        {
          subTitle: "Excellence:",
          subContent:
            "We are dedicated to maintaining the highest standards in our work.",
        },
        {
          subTitle: "Ethical Handling:",
          subContent:
            "We uphold the highest standards of integrity and ethics in all our dealings, ensuring that our clients' interests are always protected and prioritized.",
        },
        {
          subTitle: "Customer Satisfaction:",
          subContent: "Our clients' success is our top priority.",
        },
      ],
      gridSize: "col-md-6",
      fadeDirection: "up",
    },
    {
      title: "Our Team",
      content: [
        {
          subContent:
            "Our team of experts brings a wealth of knowledge and experience to the table. With over 120 years of combined experience, we have the expertise to handle even the most complex tax issues.,",
        },
        {
          subContent:
            "Specialist in direct taxation with a focus on corporate tax planning.,",
        },
        {
          subContent:
            "Authority on indirect taxation, including GST and customs duties.,",
        },
        {
          subContent: "Veteran in tax litigation and dispute resolution.,",
        },
        {
          subContent:
            "Expert in Setting up of Greenfield projects in the state of Gujarat; soon we will be expanding to have PAN India presence.,",
        },
        {
          subContent: "Export Import Rules and Regulations Expert.,",
        },
      ],
      gridSize: "col-md-12",
      fadeDirection: "up",
    },
  ];
  return (
    <>
      <div className="about-bottom">
        <div className="container my-4">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-xl-12">
              <div className="expertise-content row">
                {AboutBottomData?.map((data) => {
                  return (
                    <>
                      <div
                        className={`text-justify expertise ${data?.gridSize}`}
                      >
                        <Fade direction={data.fadeDirection} triggerOnce>
                          <h4>{data?.title}</h4>
                          <ul>
                            {data?.content?.map((contentData) => {
                              return (
                                <>
                                  <li>
                                    <h6>{contentData?.subTitle}</h6>
                                    <p>{contentData?.subContent}</p>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </Fade>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionAboutBottom;
