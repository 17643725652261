import React from "react";
// import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import MobileMenu from "../components/mobile-menu/MobileMenu";
import MobileBtn from "../components/mobile-menu/MobileBtn";
// import SearchIcon from "../assets/svg/SearchIcon";
import { useMediaQuery } from "react-responsive";
import Logo from "../assets/images/AALogo.jpg";
// import SearchIconNew from '../assets/images/searchIcon.png'
import { Link } from "react-router-dom";

const Menu = () => {
  // const triggerSearch = () => {
  //   const offcanvasMobileMenu = document.querySelector(".search_icon_inr");
  //   offcanvasMobileMenu.classList.toggle("active");
  // };
  const isMobile = useMediaQuery({ maxWidth: 768 });
  //   const location = useLocation();
  //   const currentPath = location.pathname;
  //  console.log(currentPath, 'current-path')
  const serviceData = [
    {
      serviceTitle: "GST",
      slug: "gst-advisory-services",
    },
    {
      serviceTitle: "Income tax",
      slug: "income-tax-advisory-services",
    },
    {
      serviceTitle: "Customs duty",
      slug: "customs-duty",
    },
    {
      serviceTitle: "Export-Import advisory",
      slug: "export-import-advisory-services",
    },

    {
      serviceTitle: "Green Field Project Set Up",
      slug: "green-field-project-set-up",
    },
    {
      serviceTitle: "Land Revenue-Acquisition",
      slug: "land-revenue-acquisition",
    },
  ];
  return (
    <>
      {isMobile ? (
        <>
          <div className="menu_area">
            <div className="navigation">
              <div className="container">
                <div className="logo">
                  {/* <Link to="/"> */}
                  <img
                    src={Logo}
                    alt="logo"
                    className="img-fluid Mobile-logo rounded-circle"
                  />
                  {/* </Link> */}
                </div>
                <Link to="/">
                  <p
                    className=" Mobile-Heading  fw500"
                    style={{ fontSize: "24px" }}
                  >
                    {" "}
                    Abhisa{" "}
                  </p>
                </Link>
                <MobileBtn />

                <MobileMenu />

                {/* <div className="header_cart">
                  <ul>
                    <li className="header_search">
                      <Link
                        to="#"
                        onClick={() => triggerSearch()}
                        className="cart-toggler search_icon"
                      >
                 
                        <img  src={SearchIconNew} className="img-fluid menu-icon-w" alt='searchIcon'/>
                      </Link>

                      <div className="search_icon_inr">
                        <form action="#" method="POST">
                          <div>
                            <input placeholder="Search" type="text" />
                            <button className="btn-search" type="submit">
                             
                        <img  src={SearchIconNew} className="img-fluid menu-icon-w" alt='searchIcon'/>

                            </button>
                          </div>
                        </form>
                      </div>
                    </li>
                   
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="header">
            <div className="container ">
              {/* <Navbar expand="lg" sticky="top" id="navigation">  
                        <Nav className="mr-auto">
                            <ul>
                               
                            
                                <li className="has-sub"><Link to="shop">Shop</Link>
                                    <ul>
                                        <li><Link to="shop">Shop</Link>
                                        </li>
                                        <li><Link to="single-shop">Shop Details</Link></li>                                    
                                        <li><Link to="cart">Shop Cart</Link></li>                                    
                                        <li><Link to="checkout">Checkout</Link></li>                                    
                                    </ul>
                                </li>
                                <li><Link to="contact">Contact</Link>
                                </li>
                            </ul>
                        </Nav> 
                    </Navbar> */}
              <Navbar expand="lg" sticky="top">
                <Nav
                  className="navbar navbar-expand-lg navbar-light d-flex justify-content-between nav-height"
                  id="navigation"
                >
                  <Link className="navbar-brand" to="/" contenteditable="false">
                    <img
                      src={Logo}
                      className="img-fluid rounded-circle abhisa-logo"
                    ></img>
                  </Link>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarNavDropdown"
                  ></div>
                  <ul className="navbar-nav ms-auto ml-4 ">
                    <li className=" ">
                      <Link
                        // to={process.env.PUBLIC_URL + "/"}
                        to="/"
                        className={` menu-text-south `}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="active ">
                      <Link to="/about" className="menu-text-south ">
                        About us{" "}
                      </Link>
                    </li>
                    <li className="active ">
                      <Link to="" className="menu-text-south ">
                        Services{" "}
                      </Link>
                      <ul>
                        {serviceData.map((data) => {
                          return (
                            <>
                              <li>
                                <Link to={`/services/${data.slug}`}>
                                  {data.serviceTitle}
                                </Link>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </li>
                    {/* <li className="active ">
                        <Link to="/project" className="menu-text-south ">
                          Project{" "}
                        </Link>
                      </li> */}
                    {/* <li className="active ">
                        <Link to="/gallery" className="menu-text-south ">
                          Gallery{" "}
                        </Link>
                      </li> */}
                    {/* <li className="active ">
                        <Link to="/blog" className="menu-text-south ">
                          Blog
                        </Link>
                      </li> */}
                    {/* <li className="active ">
                        <Link to="/careers" className="menu-text-south ">
                          Careers
                        </Link>
                      </li> */}
                    <li className="active ">
                      <Link to="/contact" className="menu-text-south ">
                        Contact us{" "}
                      </Link>
                    </li>
                  </ul>
                </Nav>
                {/* <div className="ml-auto">
                    <SearchIcon />
                  </div> */}
              </Navbar>
            </div>
          </div>

          {/* <div className="nav-south-asia  menu-south-asia d-flex align-items-center">
            <div className="container ">
              
              <div className="nav-south-asia">
                <Navbar>
                  <Nav className="">
                    
                    <ul className="d-flex">
                    
                      <li className=" ">
                        <Link
                          // to={process.env.PUBLIC_URL + "/"}
                          to="/"
                          className={` menu-text-south `}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/about" className="menu-text-south ">
                          About us{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/services" className="menu-text-south ">
                          Services{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/project" className="menu-text-south ">
                          Project{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/gallery" className="menu-text-south ">
                          Gallery{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/careers" className="menu-text-south ">
                          Careers
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/contact" className="menu-text-south ">
                          Contact us{" "}
                        </Link>
                      </li>
                    </ul>
                  </Nav> */}
          {/* <div className="ml-auto">
                    <SearchIcon />
                  </div> */}
          {/* </Navbar>
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
};

export default Menu;
