import LocationLogoContactUs from 'assets/svg/LocationLogoContactUs'
import React from 'react'

function SectionAddress() {
 
    return (
        <>
        <div className='container' >
   <div className='row py-4'>
  <div className='contact-address'>
 <div className='d-flex justify-content-center align-items-center'>
<LocationLogoContactUs/>
<h2 className='primary-color mx-2'>
Registered Office
</h2>

 </div>
 <div className='d-flex justify-content-center mt-2 '>

 <p className=' contact-us-address fs20px lnh-32 text-center'>
 E-412 Titanium City Center Office, Anandnagar Road, Ahmedabad, Gujarat 380015
</p>
 </div>
 <div>
    <p className='primary-color text-center fw600 fs16px'> 
    +91 8735838595    ||    advisory@abhisa.in
    </p>
 </div>
  </div>
   </div>

   {/* <div className='row py-4'>
    {
        ContactAddressData?.map((data) => (<div className='col-md-4'>
        <div className='contact-us-card'>
        <h1 className='text-center fw600 fs22px primary-color'>
       {data?.title}
        </h1>
        <p className='text-center fs18px fw500'>
{
    data?.address
}
        </p>
        <h1 className='text-center fw600 fs16px primary-color'>
      {data?.email}
        </h1>
        </div>
        </div>))
    }

   </div> */}
        </div>
        
        </>
    )
}

export default SectionAddress
