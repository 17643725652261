import React from 'react';
import { Link } from "react-router-dom";

const SubscribeSection = () => {

    return (
        <>
            <div className='container text-center my-5'>
               <div className='subscribe-class'>Like to here from us</div>
               <h1 className='subscribe-h1'>Subscribe to Get Updates, Insights & News</h1>
               <div>
                    <input type='email' className='form-group py-3 col-7' placeholder='Enter Your Email Address' />
                  <Link to='/' className="subscribe-btn">Subscribe</Link>
                  
               </div>
            </div>
        </>
    );
};

export default SubscribeSection;
