import React from "react";

import ChatQuestion from "assets/svg/ChatQuestion";
import { Fade } from "react-awesome-reveal";

import SectionCapable from "./SectionCapable";
function ServiceLayout({
  imgUrl,
  content1,
  content2,
  content3,
  CapableData,
  innerData,
  serviceMiddleData,
  CardButtonsData,
}) {
  // console.log(innerData, "innerData");
  return (
    <>
      <div className="container">
        <div className="row py-4">
          <div className="col-md-5">
            {CardButtonsData?.map((value) => {
              return (
                <>
                  <Fade direction="left" triggerOnce delay={100}>
                    <button className="btn service-inner-btn">{value}</button>
                  </Fade>
                </>
              );
            })}
          </div>
          <div className="col-md-7 ">
            <Fade direction="up" triggerOnce>
              <img src={imgUrl} alt="img" className="img-fluid " />
            </Fade>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-5 ">
            <Fade direction="up" triggerOnce delay={100}>
              <div className="any-question ">
                <div className="d-flex justify-content-center">
                  <ChatQuestion />
                </div>
                <h5 className=" text-center text-white ">
                  If you have any questions or would like to learn more about
                  our services, please contact us. We look forward to working
                  with you!
                </h5>
                <h4 className="fw600 text-center text-white fs26px">
                  <a href="tel:+919316305258" className="text-white">
                    (+91) 9316305258
                  </a>
                </h4>
                <h4 className="fw600 text-center text-white fs22px">
                  <a href="mailto:advisory@abhisa.in" className="text-white">
                    advisory@abhisa.in
                  </a>
                </h4>
              </div>
            </Fade>
          </div>

          <div className="col-md-7">
            <Fade direction="up" triggerOnce delay={100}>
              <p className="fw500 fs20px lnh-34 text-justify text-black ">
                {innerData?.subHeader}
              </p>
            </Fade>
            {innerData?.description?.map((value) => {
              return (
                <>
                  <Fade direction="up" triggerOnce delay={300}>
                    <p className="fw500 fs20px lnh-34 text-justify ">{value}</p>
                  </Fade>
                </>
              );
            })}
            {/* <p className="fw500 fs20px lnh-34 text-justify ">{content2}</p>
            <p className="fw500 fs20px lnh-34 text-justify ">{content3}</p> */}
          </div>
        </div>

        {/* <SectionCapable CapableData={CapableData} /> */}
      </div>
    </>
  );
}

export default ServiceLayout;
