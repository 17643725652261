import PrivacyPolicyContent from "components/PrivacyPolicy/PrivacyPolicyContent";
import LayoutOne from "layouts/LayoutOne";
import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

const PrivacyPolicy = () => {
    return (
      <Fragment>
        <MetaTags>
          <title>Privacy Policy</title>
          <meta name="description" content="" />
        </MetaTags>
        <LayoutOne>
            <PrivacyPolicyContent/>
        </LayoutOne>
      </Fragment>
    );
  };
  
  export default PrivacyPolicy;