import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import { Helmet } from "react-helmet";
// import Slides from '../components/Slides';
// import AboutContent from '../components/AboutContent';
// import ServiceContent from '../components/ServiceContent';
// import Funfact from '../components/Funfact';
// import WorkProcess from '../components/WorkProcess';
// import ChooseUs from '../components/ChooseUs';
// import ProductsList from '../components/ProductsList';
// import PricingList from '../components/PricingList';
// import GalleryContent from '../components/GalleryContent';
// import Team from '../components/Team';
// import Testimonial from '../components/Testimonial';
// import BlogList from '../components/BlogList';
// import ClinetCarousel from '../components/ClinetCarousel';
// import ContactForm from '../components/ContactForm';
import SectionHero from "../components/Home/SectionHero";
// import SectionServiceSlider from "../components/Home/SectionServiceSlider";
import SectionAboutUs from "../components/Home/SectionAboutUs";
import JacksonWhy from "../components/Home/JacksonWhy";
import SubscribeSection from "../components/Home/SubscribeSection";
// import SectionFeatures from "../components/Home/SectionFeatures";
// import SectionWorldWide from "../components/Home/SectionWorldWide";
// import SectionPartner from "../components/Home/SectionPartner";
// import SectionContactUs from "../components/Home/SectionContactUs";
// import SectionGroupAwards from "components/About/SectionGroupAwards";
// import { useDispatch, useSelector } from "react-redux";
// import { GetBranchData } from "redux/service/servicesAction";
import MultiSlider from "components/Home/MultiSlider";

const Home = () => {
  // const dispatch = useDispatch();
  // const isState = useSelector((state) => state);
  // console.log(isState, "products");
  // useEffect(() => {
  //   dispatch(GetBranchData());
  // }, []);
  return (
    <Fragment>
      <MetaTags>
        <title>Abhisa Advisory</title>
      </MetaTags>
      <Helmet>
        <meta
          name="description"
          content="Welcome to Abhisa Advisory! We specialize in Comprehensive Business Advisory and Consultancy Services in India. Our seasoned experts offer unparalleled insights in direct and indirect taxation, Green Field project setup, and Export Import Regulations. We are committed to excellence, innovation, and customer satisfaction."
        />

        <meta
          name="keywords"
          content="#BusinessAdvisory #ConsultancyServices #Taxation #GreenFieldProjects #ExportImport #Gujarat #India #CorporateTax #GST #CustomsDuties #TaxLitigation #DisputeResolution #IncomeTax #LandAcquisition"
        />
        <meta
          name="description"
          content="#BusinessAdvisory #ConsultancyServices #Taxation #GreenFieldProjects #ExportImport #Gujarat #India #CorporateTax #GST #CustomsDuties #TaxLitigation #DisputeResolution #IncomeTax #LandAcquisition"
        />
        <meta
          name="keywords"
          content="Business Advisory, Consultancy Services, Direct Taxation,Income Tax, Indirect Taxation, Green Field Projects, Export Import Regulations, Gujarat, India, Corporate Tax Planning, GST, IGST, CGST, Income Tax, Customs Duties, Tax Litigation, Dispute Resolution, India"
        />
        <meta
          name="keywords"
          content="Green Field Project Setup, Industrial Land Acquisition, Legal Property Consultant, Business Location Advisory, Project Finance, Subsidies, Environment Compliance, Railway Permissions, Electrical Connection, Custom Clearance, Import Export, Gujarat, India"
        />
        <meta
          name="description"
          content="Abhisa Advisory LLP specializes in Green Field Project Setup Services in India. We offer comprehensive support in industrial land acquisition, legal property consulting, business location advisory, project finance, and more. Contact us to optimize your project setup strategy."
        />
        <meta property="og:url" content="https://www.abhisa.in/" />
        <link rel="canonical" href="https://www.abhisa.in/" />
      </Helmet>
      <LayoutOne>
        <SectionHero />
        <MultiSlider />
        <SectionAboutUs />
        <JacksonWhy />
        <SubscribeSection />
        {/* <SectionFeatures />
        <SectionGroupAwards />
        <SectionWorldWide />
        <SectionPartner />
        <SectionContactUs /> */}

        {/* <Slides /> */}
        {/* <AboutContent /> */}
        {/* <ServiceContent /> */}
        {/* <Funfact /> */}
        {/* <WorkProcess /> */}
        {/* <ChooseUs />  */}
        {/* <ProductsList /> */}
        {/* <PricingList />  */}
        {/* <GalleryContent /> */}
        {/* <Team />  */}
        {/* <Testimonial /> */}
        {/* <BlogList />  */}
        {/* <ClinetCarousel /> */}
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
