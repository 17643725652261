import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
// import Breadcrumb from "../components/Breadcrumb";
import SectionHeroBanner from "../components/Common/SectionHeroBanner";
// import ContactMap from "../components/ContactMap";
import SectionMap from "../components/Contact/SectionMap";
import SectionAddress from "components/Contact/SectionAddress";
class Contact extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Abhisa Advisory | Contact Us</title>
          <meta name="description" content="" />
        </MetaTags>
        <LayoutOne>
          <div className="contact-page">
            <SectionHeroBanner
              title={"Contact Us"}
              className={"sectionContactusBg"}
            />
            <SectionAddress />
            <SectionMap />
          </div>
        </LayoutOne>
      </Fragment>
    );
  }

  constructor() {
    super();
    this.state = {
      email: "",
      name: "",
      subject: "",
      message: "",
      email_err: "",
      name_err: "",
      subject_err: "",
      message_err: "",
      return_msg: "",
      flag: false,
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
    var EmailReg = /(\w+)\s(\w+)/;
    if (e.target.value === "") this.setState({ email_err: "Required Field" });
    else if (EmailReg.test(e.target.value)) this.setState({ email_err: "" });
    else this.setState({ email_err: "Enter Valid Email" });
  }

  handleChangeName(e) {
    this.setState({ name: e.target.value });
    if (e.target.value === "") this.setState({ name_err: "Required Field" });
    else this.setState({ name_err: "" });
  }
  handleChangeSubject(e) {
    this.setState({ subject: e.target.value });
    if (e.target.value === "") this.setState({ subject_err: "Required Field" });
    else this.setState({ subject_err: "" });
  }
  handleChangeMessage(e) {
    this.setState({ message: e.target.value });
    if (e.target.message === "")
      this.setState({ message_err: "Required Field" });
    else this.setState({ message_err: "" });
  }

  handleSubmit1() {
    if (this.state.name === "") this.setState({ name_err: "Required Field" });
    if (this.state.email === "") this.setState({ email_err: "Required Field" });
    if (this.state.subject === "")
      this.setState({ subject_err: "Required Field" });
    if (this.state.message === "")
      this.setState({ message_err: "Required Field" });

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.subject === "" ||
      this.state.message === ""
    ) {
      this.setState({ return_msg: "Fill All First", flag: true });
    } else {
      this.setState({ return_msg: "Success.", flag: true });
    }
  }
}

export default Contact;
