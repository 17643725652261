import React from 'react'
import CardImg1 from '../../assets/images/business-startup-service.jpg'

const SectionServiceTop = ({data})=>{
    return(
        <>
            <div id="product-scsn">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 mt-4 col-md-5">
                            <div className="product-img">
                                <img src={CardImg1} className="img-fluid lazy  wp-post-image" alt="Business Establishments Advisory" decoding="async"></img>
                            </div>
                        </div>
                        <div className="col-xxl-8 col-md-7 col-lg-7 align-self-center">
                            <div className="product-details">
                                <h2>{data?.mainHeader ?? ""}</h2>
                                <p className='highlight-para'>
                                  {data?.subHeader ?? ""}
                                </p>
                                {
                                    data?.description.map((value)=>(
                                      <p>
                              {value}                                 
                                </p>
                                    
                                    ))
                                }
                              
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    )
}

export default SectionServiceTop;