import React from 'react'
import { Fade } from 'react-awesome-reveal'


function SectionOurVision() {
    return (
        <>
        <div className='container'>
            <div className='row py-5'>
              <div className='col-md-12 text-center vision-about'>
                <Fade direction="left" triggerOnce>
                  <h3>Our Vision</h3>
                </Fade>
                 <Fade direction="up" triggerOnce>
                    <p className='text-justify vision-text'>
                    At Abhisa Advisory, we envision a future where businesses can focus on growth and innovation, while we handle their statutory, regularoty and legal  challenges with precision and expertise.
                    </p>
                 </Fade>
                 
              </div>
              <div className='col-md-12 text-center mt-5 vision-about'>
              <Fade direction="left" triggerOnce>
                <h3>Our Mission</h3>
                </Fade>
                <Fade direction="up" triggerOnce>
                    <p className='text-justify vision-text'>
                    Our mission is to deliver top-notch advisory services tailored to the unique needs of each client. We are committed to excellence, innovation, and customer satisfaction, ensuring that our clients receive the best possible guidance and support.
                    </p>
                </Fade>
              </div>
             

            </div>
        </div>
        </>
    )
}

export default SectionOurVision
