import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import SectionHeroBanner from "../components/Common/SectionHeroBanner";
// import MainTitle from "components/Common/MainTitle";
// import ServiceCardLayout2 from "components/Service/ServiceCardLayout2";
// import ServiceCardLayout1 from "components/Service/ServiceCard";
import CardImg1 from "../assets/images/business-startup-service.jpg";
import CardImg2 from "../assets/images/Workout1.png";
import CardImg3 from "../assets/images/Workout3.png";
import CardImg4 from "../assets/images/Workout4.png";
import DrillingService from "../assets/images/DrillingService.png";
import GeneratorSets from "../assets/images/GeneratorState.png";
import DuelFuel from "../assets/images/DueFuelEngine.png";
import CrudOil from "../assets/images/CrudOil.png";
import SectionServiceTop from "components/ServiceInnerPage/SectionServiceTop";
import SectionServiceBottom from "components/ServiceInnerPage/SectionServiceBottom";
import ServiceLayout from "components/ServiceInnerPage/ServiceLayout";
// import SAFETY from '../assets/images/IWCF.png'
class Services extends Component {
  render() {
    const CardDataSection1 = [
      {
        subTitle:
          "PKMODI & CO. is a leading name in the field of business start-up and business establishments advisory services",
        content1:
          "The firm has experience of more than 30 years in the field of Auditing, Taxation, Management, Company law and Foreign Exchange Regulations. The experience and expertise of above referred domains administers setting up of Business in India and outside India. With a global foot-print clientele exposure, the Process of setting up of start-up Unit, liaison office, branch office or project office can be handled very smoothly.",
        content2:
          "We possess a dedicated team of legal, financial and management experts to help start-up to establish their desire entity. We ensures in meeting various milestones, to save significant financial resources and obliterating uncertainties and risks through our personal attention.",
      },
      {
        subTitle: "GIFTCITY",
        content:
          "Gujarat International Finance Tech-City is upcoming recognised financial and IT services hub at Gandhinagar. It is a physical territory of India however for the purpose of RBI and FEMA regulation it is treated as territory outside India. Many financial Institutions, stock exchanges, insurance companies and other financial services set-up their offices and made operational. We at PKMODI & CO. are well versed with regulations of IFSCA, which is the regulatory body. We offer full fledged range of services from conceptualization of Business entity till make them operational.",
      },
    ];

    const CardDataSection2 = [
      {
        CardImg: GeneratorSets,
        Title: "Generator Sets ",
        content:
          " We provide wide range of Diesel Generators on rental basis with excellent services. We offer 05KVA to 25KVA, 35KVA to 200 KVA and 250KVA, 320KVA, 380KVA, 500KVA, 600KVA, 750KVA, ",
        slug: "generator-sets",
      },
      {
        CardImg: DuelFuel,
        Title: "Duel Fuel Engines Services",
        content:
          "We provide operation and maintenance services for Offshore Drilling Rigs, Onshore Drilling and Workover rigs, Oil and Gas installations, Sucker Rod Pump Units etc.",
        slug: "duel-fuel-engines-services",
      },
      {
        CardImg: DrillingService,
        Title: "Drilling Services",
        content:
          "Abhisa , primarily an Oil and Gas service provider, has diversified in the oil and gas Exploration and...",
        slug: "drilling-services",
      },
      {
        CardImg: CrudOil,
        Title: "Crude Oil transportation Services ",
        content:
          "We offer Nitrogen Pumping Unit services for various oilfield purposes. NPU is a hydraulic heat recovery Liquid Nitrogen Pump and vaporizer system.",
        slug: "crude-oil-transportation-services",
      },
    ];

    return (
      <Fragment>
        <MetaTags>
          <title>Abhisa Advisory | Services</title>
          <meta name="description" content="" />

        </MetaTags>
        <LayoutOne>
          <SectionHeroBanner
            title={"Services"}
            className={"sectionServicesBg"}
          />
          <SectionServiceTop CardDataSection1={CardDataSection1} />
          <SectionServiceBottom />
        </LayoutOne>
      </Fragment>
    );
  }
}

export default Services;
