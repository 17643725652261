import React from "react";
import RightArrow from "../../assets/svg/RightArrow";
// import WhatsAppIcon from '../../assets/svg/WhatsAppIcon'
// import SouthAsiaLogo from '../../assets/images/logoWhatsAPP.png'
// import WhatsAppIconImg from '../../assets/images/WhatsAppIcon.png'
// import WhatsAppChatBox from '../../assets/images/WhatsAppChatBox.png'
// import CloseIcon from 'assets/svg/CloseIcon'
// import WhatsAppSendButton from 'assets/svg/WhatsAppSendButton'
// import HelloIcon from 'assets/svg/HelloIcon'
import sectionHeaderImg from "../../assets/images/sectionHeader.jpg";

import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

function SectionHero() {
  // const [chatBox, setChatBox] = useState(false)
  // const [whatsappInput, setWhatsAppInput] = useState('')

  return (
    <>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Fade direction="left" triggerOnce>
                <h1 className="sectionHeroBg-Heading">
                  {" "}
                  A Team of Domain Experts Committed to Delivering <br />{" "}
                  Cost-Effective Services
                </h1>
              </Fade>
            </div>
            <div className="col-md-6">
              <Fade triggerOnce>
                <img src={sectionHeaderImg} />
              </Fade>
            </div>
          </div>
          {/* <div className='d-flex justify-content-end  align-items-end  '>
        {
            !chatBox && 
            <button className='btn sectionHeroBg-Enquiry d-flex align-items-center ' onClick={()=>{setChatBox(true)}} >
                <div className='position-relative'>
    <img src={WhatsAppIconImg} alt='whatsappIcon' className='img-fluid whatsappIcon-img'/>
    <span className='whatsappIcon-img-text'>

    ENQUIRY
    </span>
                </div>

    </button> 
        }

    {
        chatBox &&  <>
        <div className='whatsAppCard'>
    <div className='whatsAppHeader d-flex  '>
    <img src={SouthAsiaLogo} className='' alt='logo' />
    <p className='text-white fs16px px-2'>
        Abhisa   <br></br>
        <span  style={{ fontSize:'12px' }}>

    Business account
    </span>
    </p>
    <div className='ml-auto '>
        <button className='btn' onClick={() => { setChatBox(false)}}>

        <CloseIcon/>
        </button>
    </div>
    </div>
    <div className='whatsAppBody position-relative'>
    <img src={WhatsAppChatBox} alt='icon'/>
    <p className='whatsAppTitleChat'>  Abhisa    </p>
    <p className='whatsAppBodyText'>
    Hello there!  
    <HelloIcon/> <br></br>
    How can we help?
    </p>
    </div>
    <div className='whatsAppFooter d-flex align-items-center'>
    <input type='text' placeholder='Type a message..' className='custom-whatsapp-input' onChange={(e)=> setWhatsAppInput(e.target.value)} />
    <div className='ml-auto'>
    <a
                    href={`https://api.whatsapp.com/send?l=en&phone=919316305258&text=${whatsappInput}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                >

    <button className='btn '>

        <WhatsAppSendButton/>
    </button>
                </a>
    </div>
    </div>
        </div>
        
        </>
    }

    </div> */}
        </div>
      </div>
    </>
  );
}

export default SectionHero;
