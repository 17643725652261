import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
// import AboutContent from '../components/AboutContent';
// import Team from '../components/Team';
// import WorkProcess from '../components/WorkProcess';
// import Testimonial from '../components/Testimonial';
// import Breadcrumb from "../components/Breadcrumb";
import SectionHeroBanner from "../components/Common/SectionHeroBanner";
import SectionAboutConsultancy from "../components/About/SectionAboutConsultancy";
// import SectionOurExpertise from "../components/About/SectionOurExpertise";
import SectionOurVision from "../components/About/SectionOurVision";
// import SectionQuality from "../components/About/SectionQuality";
// import SectionClient from "../components/About/SectionClient";
// import SectionGroup from "../components/About/SectionGroup";
// import SectionCollaborate from "../components/About/SectionCollaborate";
// import SectionAwards from "../components/About/SectionAwards";
// import SectionGroupAwards from "../components/About/SectionGroupAwards";
// import SectionActivity from "../components/About/SectionActivity";
import SectionAboutBottom from "components/About/SectionAboutBottom";

const About = () => {
  return (
    <Fragment>
      <MetaTags>
        <title> About</title>
        <meta name="description" content="" />
      </MetaTags>
      <LayoutOne>
        <div className="about-page">
          {/*====================  breadcrumb area ====================*/}
          <SectionHeroBanner title={"About Us"} className={"sectionAboutBg"} />
          <SectionAboutConsultancy />
          {/* <SectionOurExpertise/> */}
          <SectionOurVision />
          <SectionAboutBottom />
          {/* <SectionQuality/>
               <SectionClient/>
               <SectionGroup/>
               <SectionCollaborate/>
               <SectionAwards/> */}
          {/* <SectionGroupAwards/> */}
          {/* <SectionActivity/> */}
          {/* <Breadcrumb title="About Usa" /> */}

          {/*====================  End of breadcrumb area  ====================*/}

          {/*====================  About area ====================*/}

          {/* <AboutContent /> */}

          {/*==================== End:  About area ====================*/}

          {/*==================== Team Mamber area  ====================*/}

          {/* <Team /> */}

          {/*====================  End Team Mamber area  ====================*/}

          {/*====================  Working Process area  ====================*/}

          {/* <WorkProcess /> */}

          {/*====================  End: Working Process area  ====================*/}

          {/*====================  Client Satisfait area  ====================*/}

          {/* <Testimonial /> */}

          {/*====================  End: Client Satisfait area  ====================*/}
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default About;
