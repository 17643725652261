import React from "react"; 
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import SectionBanner from "components/Common/SectionBanner";
import SectionHeroBanner from "components/Common/SectionHeroBanner";

const PrivacyPolicyContent = () => {
    const PrivacyPolicyData = [
        {
            title: "1. What does this policy cover",
            content:[
                {
                    subContent: "Welcome to Abhisa! This privacy policy outlines how we collect, use, share, and protect your personal information. By using our website, you agree to the terms described here."
                }
            ]
        },
        {
            title: "2. Information We Collect",
            MainsubTitle: "We collect the following types of information:",
            content:[
                {
                    subTitle: "(a) Personal Information:",
                    subContent: "When you interact with our website (e.g., sign up for newsletters, make purchases), we may collect your name, email address, and other relevant details."
                },
                {
                    subTitle: "(b) Usage Data:",
                    subContent: "We automatically collect information about your interactions with our site, such as IP addresses, browser type, and device information."
                },
                {
                    subTitle: "(c) Cookies and Tracking Technologies:",
                    subContent: "We use cookies and similar technologies to enhance your browsing experience and analyze site traffic."
                }
            ]
        },
        {
            title: "3. How We Use Your Information",
            MainsubTitle: "We use your information for the following purposes:",
            content:[
                {
                    subContent: "To provide and improve our services"
                },
                {
                    subContent: "To personalize your experience"
                },
                {
                    subContent: "To communicate with you (e.g., newsletters, order updates)"
                },
                {
                    subContent: "To comply with legal obligations"
                }
            ]
        },
        {
            title: "4. Data Sharing",
            MainsubTitle: "We do not sell your personal information. However, we may share it with:",
            content:[
                {
                    subContent: "Service providers (e.g., hosting, analytics)"
                },
                {
                    subContent: "Legal authorities (if required by law)"
                },
            ]
        },
        {
            title: "5. Your Rights",
            MainsubTitle: "You have the right to:",
            content:[
                {
                    subContent: "Access and update your personal information"
                },
                {
                    subContent: "Opt out of marketing communications"
                },
                {
                    subContent: "Request data deletion"
                },
            ]
        },
        {
            title: "6. Security Measures",
            content:[
                {
                    subContent: "We take reasonable steps to protect your data. However, no method of transmission over the internet is 100% secure."
                },
            ]
        },
        {
            title: "7. International Users",
            content:[
                {
                    subContent: "If you’re visiting from outside India, be aware that your data may be transferred to and processed in other countries. By using our website, you consent to this transfer."
                },
            ]
        },
        {
            title: "8. Jurisdiction and Arbitration",
            content:[
                {
                    subContent: "Any disputes arising from or related to this privacy policy shall be governed by the laws of India. In case of any disagreement, both parties agree to resolve the matter through binding arbitration. The arbitration shall take place in Ahmedabad, India, and the language used shall be English. The decision of the arbitrator(s) will be final and binding."
                },
            ]
        },
        {
            title: "9. Contact Us",
            content:[
                {
                    subContent: "If you have any questions or concerns about our privacy policy, please contact us at contact@abhisa.in."
                },
            ]
        },

    ]
    return (
        <>
            <SectionHeroBanner title={"Privacy Policy"} className={"sectionAboutBg"} />
            <PrivacyPolicyPage 
                PrivacyPolicyData={PrivacyPolicyData}
            />
        </>
    )
  };
  
export default PrivacyPolicyContent;