import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import siteSelection from "../assets/images/site-selection.jpg";
import taxationService from "../assets/images/tax_services.jpg";
import technologyConsulting from "../assets/images/Technology-consulting.png";
import companySecretarial from "../assets/images/company-secretarial.jpg";
import customeDuty from "../assets/images/Customs-duty.png";
// import gstImg from "../assets/images/GST.jpg";
// import incomeTax from "../assets/images/income-tax-advisory-services.webp";
import landRevenue from "../assets/images/land-revenue1.jpg";
import femaImg from "../assets/images/fema.jpg";
import regulatoryCompliance from "../assets/images/Regulatory-Compliance.jpg";
import gstImg from "../assets/images/AbhisaGSTFinal.png";
import greenLayout from "../assets/images/AbhisaLandAquisitionndGreenFieldManufacturingProjectSetUp.png";
import exportImport from "../assets/images/AbhisaImportAndExportConsultancy.png";
import incomeTaxAdvisoryImg from "../assets/images/AbhisaIncomeTaxAdvisoryServicesOfIndia.png";
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";
import SectionServiceBottom from "components/ServiceInnerPage/SectionServiceBottom";
import ServiceLayout from "components/ServiceInnerPage/ServiceLayout";
import SectionBanner from "components/Common/SectionBanner";
import SectionCapableAbh from "components/ServiceInnerPage/SectionCapableAbh";
import RelatedService from "components/ServiceInnerPage/RelatedService";
import { Fade } from "react-awesome-reveal";
import SectionHeroBanner from "components/Common/SectionHeroBanner";
// import CardImg1 from "../../assets/images/business-startup-service.jpg";

function ServiceInnerPage() {
  const ServiceInnerPageData = [
    {
      title: "Customs duty",
      slug: "customs-duty",
      metaDescription:
        "Abhisa Advisory provides expert Customs Duty Advisory Services. Navigate import/export regulations, compliance, and specialized services. Contact us for seamless customs clearances and compliance.",
      metaKeywords:
        "Customs Duty, Import Export, Compliance, Regulations, Clearances, Litigation, DRI, India",
      ogDescription:
        "Expert Customs Duty Advisory Services. Navigate import/export regulations, compliance, and specialized services. Contact us for seamless customs clearances and compliance.",
      ogTitle: "Customs Duty Advisory Services - Abhisa Advisory",
      CardButtonsData: [
        "Tariff Classification",
        "Customs Valuation",
        "Duty Optimization",
        "Customs Compliance",
        "Trade Agreements",
      ],
      imgUrl: customeDuty,
      innerData: {
        mainHeader: "Customs duty",
        subHeader:
          "Providing guidance on customs duty regulations and compliance requirements for import and export of goods.",
        description: [
          "At ABHISA, we specialize in providing comprehensive custom duty services designed to facilitate smooth import and export operations for businesses of all sizes. Our dedicated team ensures compliance with customs regulations while optimizing duty payments and operational efficiency.",
        ],
      },
      serviceMiddleData: [
        {
          ServiceMiddletitle: "Comprehensive Custom Duty Services at ABHISA",
          content: [
            {
              contentTitle: "Expert Classification and Valuation",
              content1:
                "ABHISA provides expert guidance in accurately classifying and valuing imported goods for customs purposes, ensuring compliance with tariff regulations and minimizing risks.",
            },
            {
              contentTitle: "Customs Declarations and Documentation",
              content1:
                "We specialize in the preparation and submission of customs declarations and required documentation, facilitating smooth customs clearance processes and reducing delays.",
            },
          ],
        },

        {
          ServiceMiddletitle: "Duty Drawback and Refund Claims",
          content: [
            {
              contentTitle: "Optimization of Financial Benefits",
              content1:
                "We optimize financial benefits through expert management of duty drawback and customs duty refund claims, enhancing cash flow and operational efficiency for businesses.",
            },
            {
              contentTitle: "Strategic Planning for Customs Optimization",
              content1:
                "ABHISA provides strategic planning services to optimize customs processes, ensuring businesses maximize benefits such as input tax credits and refunds.",
            },
          ],
        },
      ],
      serviceBottomData: [
        {
          ServiceBottamtitle: "Customs Duty Services at ABHISA",
          content: [
            {
              content1:
                "Classification and valuation of imported goods for customs purposes.",
            },
            {
              content1:
                "Preparation and submission of customs declarations and documentation.",
            },
            {
              content1:
                "Advisory on import/export regulations and customs compliance.",
            },
            {
              content1:
                "Handling customs audits, investigations, and disputes.",
            },
            {
              content1:
                "Assistance with duty drawback and customs duty refund claims.",
            },
          ],
        },
        {
          ServiceBottamtitle:
            "GST (Goods and Services Tax) Compliance Services",
          content: [
            {
              content1:
                "Registration, filing, and compliance management for GST.",
            },
            {
              content1:
                "Advisory on GST implications for business transactions.",
            },
            {
              content1: "Handling GST audits, assessments, and disputes.",
            },
            {
              content1:
                "Optimization of GST processes to minimize tax liabilities.",
            },
            {
              content1:
                "Assistance with GST refunds and input tax credit claims.",
            },
          ],
        },
      ],
      relatedServiceData: [
        {
          imgUrl: gstImg,
          Link: "/services/gst-advisory-services",
          serviceName: "GST",
        },
        {
          imgUrl: incomeTaxAdvisoryImg,
          Link: "/services/income-tax-advisory-services",
          serviceName: "Income Tax",
        },
      ],
    },
    {
      title: "GST",
      slug: "gst-advisory-services",
      metaDescription:
        "Abhisa Advisory LLP offers comprehensive GST Advisory Services including registration, compliance, consulting, appeals, and training. Contact us to streamline your GST compliance and optimize your tax strategy.",
      metaKeywords:
        "GST Advisory, GST Registration, GST Compliance, GST Consulting, GST Appeals, GST Training, GST Returns, Input Tax Credit, GST Rates, India",
      ogDescription:
        "We offer comprehensive GST Advisory Services including registration, compliance, consulting, appeals, and training. Contact us to streamline your GST compliance and optimize your tax strategy",
      ogTitle: "Abhisa Advisory LLP - GST Advisory Services",
      CardButtonsData: [
        "GST Registration",
        "GST Compliance",
        "GST Return Filing",
        "GST Advisory",
        "GST Audit",
      ],
      imgUrl: gstImg,
      innerData: {
        mainHeader: "GST",
        subHeader:
          "Providing guidance on GST regulations and compliance requirements, including GST registration, filing of returns, and claiming input tax credit.",
        description: [
          "The firm has experience of more than 30 years in the field of Auditing, Taxation, Management, Company law and Foreign Exchange Regulations. The experience and expertise of above referred domains administers setting up of Business in India and outside India. With a global foot-print clientele exposure, the Process of setting up of start-up Unit, liaison office, branch office or project office can be handled very smoothly.",
          "We possess a dedicated team of legal, financial and management experts to help start-up to establish their desire entity. We ensures in meeting various milestones, to save significant financial resources and obliterating uncertainties and risks through our personal attention.",
        ],
      },
      serviceMiddleData: [
        {
          content: [
            {
              contentTitle: "GST Registration",
              content1:
                "Step-by-step assistance with GST registration for new businesses and entities crossing the threshold.",
              content2:
                "Advisory on GST registration requirements and procedures for different business types.",
            },
            {
              contentTitle: "GST Compliance",
              content1:
                "Comprehensive support to ensure timely filing of GST returns (GSTR-1, GSTR-3B, etc.). GSTR-9 reconciled with the P&L Accounts.",
              content2:
                "Review and reconciliation of GST data to minimize errors and penalties.",
              content3:
                "Compliance audits and GST health checks to assess adherence to regulatory requirements.",
            },
            {
              contentTitle: "GST Advisory and Consulting",
              content1:
                "Expert advice on GST implications for business transactions, including supply chain management, invoicing, and input tax credit (ITC) eligibility.",
              content2:
                "Guidance on GST rates, classifications, and exemptions applicable to specific industries or transactions.",
            },
            {
              contentTitle: "GST Appeals and Representations",
              content1:
                "Representation before GST authorities for assessments, audits, appeals, reply of Show Cause Notices ( DRC-01A and DRC-01 and cases booked by the enforcement agencies like DGGI, CGST/SGST preventive formation.",
              content2:
                "Assistance in responding to GST notices and handling litigation matters.",
            },
            {
              contentTitle: "GST Training and Workshops",
              content1:
                "Customized training programs for businesses and their teams to understand GST fundamentals, compliance best practices, and updates.",
              content2:
                "Workshops on advanced GST topics and industry-specific challenges.",
            },
          ],
        },
      ],
      whyChooseUs: [
        {
          title: "Expertise and Experience:",
          content:
            "Our team comprises seasoned GST professionals with in-depth knowledge of GST laws and regulations.",
        },
        {
          title: "Our Clients & Client-Centric Approach:",
          content:
            "We cater to a diverse clientele, including domestic and multinational businesses.  ",
          content1:
            "We believe that the key to a secure future lies in continuously enhancing and expanding our services to meet our clients’ needs. Client satisfaction is at the core of everything we do, which is why we emphasize teamwork and tailor each project to meet specific requirements.",
          content2:
            "Our clients trust Abhisa Advisory to provide timely and accurate guidance, helping them achieve their strategic goals. We go beyond just seeing businesses and assets; we understand your challenges, recognize your objectives, and deliver the best possible solutions and results.",
        },
        {
          title: "Technology Integration:",
          content:
            "Utilization of advanced GST software for seamless compliance and real-time updates.",
        },
        {
          title: "Comprehensive Support:",
          content:
            "From initial consultation to ongoing advisory services, we offer end-to-end support to simplify GST compliance for your business.",
        },
      ],
      serviceBottomData: [
        {
          ServiceBottamtitle: "Domestic GST Services",
          content: [
            {
              content1:
                "GST Registration and Compliance for Domestic Businesses.",
            },
            {
              content1:
                "Filing of GST Returns (monthly, quarterly, and annual).",
            },
            {
              content1: "GST Advisory on Tax Planning and Optimization.",
            },
            {
              content1: "Consultancy on GST Audit and Compliance Reviews.",
            },
            {
              content1: "Training Programs on GST for Business Compliance.",
            },
          ],
        },
        {
          ServiceBottamtitle: "International GST Services",
          content: [
            {
              content1: "GST Compliance for Cross-border Transactions.",
            },
            {
              content1:
                "Management of GST Refunds for International Operations.",
            },
            {
              content1: "Strategic GST Planning for Global Business Expansion.",
            },
            {
              content1:
                "Customized GST Advisory Services for Overseas Entities.",
            },
            {
              content1:
                "Implementation of GST Compliance Frameworks Internationally.",
            },
          ],
        },
      ],
      contactUs: [
        {
          title:
            "Ready to streamline your GST compliance and optimize your tax strategy? Contact us today to discuss how Abhisha Advisory LLP can support your business.",
        },
      ],
      calltoAction: [
        {
          title:
            "Explore our comprehensive range of GST services and discover how we can help you achieve GST compliance with confidence.",
        },
      ],
      relatedServiceData: [
        {
          imgUrl: incomeTaxAdvisoryImg,
          Link: "/services/income-tax-advisory-services",
          serviceName: "Income Tax",
        },
        {
          imgUrl: landRevenue,
          Link: "/services/land-revenue-acquisition",
          serviceName: "Land Revenue-Acquisition",
        },
      ],
    },
    {
      title: "Income tax",
      slug: "income-tax-advisory-services",
      metaDescription:
        "Abhisa Advisory LLP offers comprehensive Income Tax Advisory Services including tax planning, return preparation, compliance, audit support, and consulting. Contact us to optimize your tax strategy and ensure compliance with income tax laws.",
      metaKeywords:
        "Income Tax Advisory, Tax Planning, Tax Return Preparation, Tax Compliance,Audit Support, Tax Consulting, Tax Deductions, Tax Credits, Business Tax Planning, India",
      ogTitle: "Abhisa Advisory LLP - Income Tax Advisory Services",
      ogDescription:
        "We offer comprehensive Income Tax Advisory Services including tax planning, return preparation, compliance, audit support, and consulting. Contact us to optimize your tax strategy and ensure compliance with income tax laws.",
      CardButtonsData: [
        "Tax Planning",
        "Tax Compliance",
        "Tax Return Preparation",
        "Tax Advisory",
        "Tax Audit Support",
      ],
      imgUrl: incomeTaxAdvisoryImg,
      innerData: {
        mainHeader: "Income Tax",
        subHeader:
          "Providing guidance on income tax regulations and compliance requirements, including tax planning, tax returns, and tax audits.",
        description: [
          "With over 30 years of experience in the fields of Auditing, Taxation, Management, Company Law, and Foreign Exchange Regulations, our firm is well-equipped to handle all aspects of Income Tax compliance and planning. Our expertise in these domains ensures a smooth and efficient process for both individuals and businesses in managing their tax obligations.",
        ],
      },
      serviceMiddleData: [
        {
          content: [
            {
              contentTitle: "Income Tax Planning",
              content1:
                "Personalized tax planning strategies to minimize tax liabilities and maximize savings.",
              content2:
                "Advice on tax-efficient investments, deductions, and credits available under current tax laws.",
            },
            {
              contentTitle: "Income Tax Return Preparation and Filing",
              content1:
                "Comprehensive preparation and accurate filing of income tax returns for individuals, businesses, estates, and trusts.",
              content2: "E-filing services for convenience and efficiency.",
            },
            {
              contentTitle: "Tax Compliance and Audit Support",
              content1:
                "Ensuring compliance with income tax laws and regulations.",
              content2:
                "Representation and assistance during income tax audits and assessments.",
            },
            {
              contentTitle: "Tax Advisory and Consulting",
              content1:
                "Expert advice on complex tax matters, including capital gains, property transactions, foreign income, and expatriate taxation.",
              content2:
                "Guidance on tax implications of business transactions, mergers, acquisitions, and restructuring.",
            },
            {
              contentTitle: "Tax Deductions and Credits",
              content1:
                "Identification and optimization of available tax deductions and credits.",
              content2:
                "Assistance in claiming credits such as child tax credits, education credits, and charitable contributions.",
            },
            {
              contentTitle: "Tax Planning for Businesses",
              content1:
                "Structuring tax-efficient business entities (e.g., LLCs, S-Corps) and transactions.",
              content2:
                "Strategic planning for corporate tax compliance and optimization.",
            },
            // {
            //   contentTitle: "Appeals and Representations:",
            //   content1:
            //     "Representation before Income Tax authorities for assessments, audits, appeals,reply of Show Cause Notices and cases booked by the enforcement agencies like Investigation wing, or Survey by Income Tax authorities.",
            //   content2:
            //     "Assistance in responding to Income Tax Show Cause Notices and handling litigation matters.",
            // },
          ],
        },
      ],
      whyChooseUs: [
        {
          title: "Expertise and Experience:",
          content:
            "Our team comprises seasoned tax professionals with extensive knowledge of income tax laws and regulations.",
        },
        {
          title: "Our Clients & Client-Centric Approach:",
          content:
            "We cater to a diverse clientele, including domestic and multinational businesses.  ",
          content1:
            "We believe that the key to a secure future lies in continuously enhancing and expanding our services to meet our clients’ needs. Client satisfaction is at the core of everything we do, which is why we emphasize teamwork and tailor each project to meet specific requirements.",
          content2:
            "Our clients trust Abhisa Advisory to provide timely and accurate guidance, helping them achieve their strategic goals. We go beyond just seeing businesses and assets; we understand your challenges, recognize your objectives, and deliver the best possible solutions and results.",
        },
        {
          title: "Technology Integration:",
          content:
            "Utilization of advanced GST software for seamless compliance and real-time updates.",
        },
        {
          title: "Comprehensive Support:",
          content:
            "From initial consultation to ongoing advisory services, we offer end-to-end support to simplify GST compliance for your business.",
        },
      ],
      serviceBottomData: [
        {
          ServiceBottamtitle:
            "Domestic Business Establishments Regulatory Compliance Services",
          content: [
            {
              content1:
                "Registration of Proprietorship Firms, Partnership Firms, Joint Ventures, Private Trusts, LLP, Private Limited Companies, Limited Companies and HUF.",
            },
            {
              content1:
                "Fulfilling statutory quarterly and annual compliance requirements.",
            },
            {
              content1:
                "Registration of trademarks, logos, intellectual copyrights, etc.",
            },
            {
              content1:
                "Help in Drafting Will, Gift Deed, Affidavits and all other types of business agreements.",
            },
          ],
        },
        {
          ServiceBottamtitle:
            "Overseas Business Establishments Regulatory Compliance Services",
          content: [
            {
              content1: "Establishment of the overseas Liaison offices.",
            },
            {
              content1: "Establishment of overseas Branch Offices.",
            },
            {
              content1: "Establishment of wholly owned subsidiaries.",
            },
            {
              content1: "Setting up entity in the Special Economic Zones (SEZ)",
            },
            {
              content1: "Setting up Joint Ventures (JV)",
            },
          ],
        },
      ],
      contactUs: [
        {
          title:
            "Ready to optimize your tax strategy and ensure compliance with income tax laws? Contact us today to discuss how Abhisa Advisory LLP can support your tax needs.",
          // content:
          //   "Contact us today to discuss how Abhisa Advisory LLP can support your  needs.",
        },
      ],
      calltoAction: [
        {
          title:
            "Explore our comprehensive range of services and discover how we can help you achieve  project  efficiency and peace of mind.",
        },
      ],
      relatedServiceData: [
        {
          imgUrl: landRevenue,
          Link: "/services/land-revenue-acquisition",
          serviceName: "Land Revenue-Acquisition",
        },
        {
          imgUrl: exportImport,
          Link: "/services/export-import-advisory-services",
          serviceName: "Export Import Advisory",
        },
      ],
    },
    {
      title: "Land Revenue-Acquisition",
      slug: "land-revenue-acquisition",
      ogDescription:
        "Expert guidance in Land Revenue Acquisition Services. Navigate import/export regulations, compliance, and specialized services. Contact us for seamless customs clearances and compliance.",
      metaDescription:
        "Abhisa Advisory provides expert guidance in Land Revenue Acquisition Services. Navigate import/export regulations, compliance, and specialized services. Contact us for seamless customs clearances and compliance.",
      ogTitle: "Land Revenue Acquisition Services - Abhisa Advisory",
      CardButtonsData: [
        "Land Title Verification",
        "Property Tax Assessment",
        "Land Use Planning",
        "Land Acquisition Consultation",
        "Land Revenue Dispute Resolution",
      ],
      imgUrl: landRevenue,
      innerData: {
        mainHeader: "Business Start-up and Establishment Services",
        subHeader:
          "Providing guidance on land revenue regulations and compliance requirements, including land acquisition, land use, and land registration.",
        description: [
          "The firm has experience of more than 30 years in the field of Auditing, Taxation, Management, Company law and Foreign Exchange Regulations. The experience and expertise of above referred domains administers setting up of Business in India and outside India. With a global foot-print clientele exposure, the Process of setting up of start-up Unit, liaison office, branch office or project office can be handled very smoothly.",
          "We possess a dedicated team of legal, financial and management experts to help start-up to establish their desire entity. We ensures in meeting various milestones, to save significant financial resources and obliterating uncertainties and risks through our personal attention.",
        ],
      },
      serviceBottomData: [
        {
          ServiceBottamtitle:
            "Domestic Business Establishments Regulatory Compliance Services",
          content: [
            {
              content1:
                "Registration of Proprietorship Firms, Partnership Firms, Joint Ventures, Private Trusts, LLP, Private Limited Companies, Limited Companies and HUF.",
            },
            {
              content1:
                "Fulfilling statutory quarterly and annual compliance requirements.",
            },
            {
              content1:
                "Registration of trademarks, logos, intellectual copyrights, etc.",
            },
            {
              content1:
                "Help in Drafting Will, Gift Deed, Affidavits and all other types of business agreements.",
            },
          ],
        },
        {
          ServiceBottamtitle:
            "Overseas Business Establishments Regulatory Compliance Services",
          content: [
            {
              content1: "Establishment of the overseas Liaison offices.",
            },
            {
              content1: "Establishment of overseas Branch Offices.",
            },
            {
              content1: "Establishment of wholly owned subsidiaries.",
            },
            {
              content1: "Setting up entity in the Special Economic Zones (SEZ)",
            },
            {
              content1: "Setting up Joint Ventures (JV)",
            },
          ],
        },
      ],
      relatedServiceData: [
        {
          imgUrl: greenLayout,
          Link: "/services/green-field-project-set-up",
          serviceName: "Green Field Project Set Up",
        },
        {
          imgUrl: exportImport,
          Link: "/services/export-import-advisory-services",
          serviceName: "Export Import Advisory",
        },
      ],
    },

    {
      title: "Green Field Project Set Up",
      metaDescription:
        "Abhisa Advisory provides expert Greenfield Project Setup Services. Navigate comprehensive business advisory, legal property consulting, and strategic planning for corporate tax compliance. Contact us for seamless project setup and compliance.",
      metaKeywords:
        "Greenfield Project Setup, Business Advisory, Legal Property Consulting, Land Aquisition, Gujarat, India",
      ogDescription:
        "Expert Greenfield Project Setup Services. Navigate comprehensive business advisory, legal property consulting, and strategic planning for corporate tax compliance. Contact us for seamless project setup and compliance.",
      ogTitle: "Greenfield Project Setup Services - Abhisa Advisory",
      clientBenefits: [
        {
          content1:
            "Peace of mind with Customs compliant practices and risk-mitigated operations.",
        },
        {
          content2:
            "Access to expert advice and guidance for complex customs scenarios.",
        },
      ],
      CardButtonsData: [
        "Planning",
        "Feasibility",
        "Infrastructure",
        "Compliance",
        "Sustainability",
      ],

      slug: "green-field-project-set-up",
      imgUrl: greenLayout,
      innerData: {
        mainHeader: "Regulatory Compliance",
        subHeader:
          "Providing guidance on regulatory compliance requirements for setting up green field projects/services in India and abroad.",
        description: [
          "ABHISA is a renowned leader in the field of regulatory compliance services. With our deep expertise and comprehensive understanding of regulatory frameworks, we assist businesses in navigating the complexities of compliance across various industries. Our dedicated team ensures that your business adheres to all relevant regulations and standards, mitigating risks and promoting sustainable growth.",
        ],
      },
      serviceMiddleData: [
        {
          content: [
            {
              contentTitle: "Industrial Land Acquisition:",
              content1:
                "We assist businesses in acquiring industrial land, ensuring a smooth and hassle-free process from identification to final purchase.",
            },
            {
              contentTitle: "NA NOC and Title Clearance for Land:",
              content1:
                "Our team handles Non-Agricultural (NA) NOC and title clearance, ensuring all legal requirements are met for land use conversion.",
            },
            {
              contentTitle: "Legal Property Consultant:",
              content1:
                "We offer expert legal advice on property matters, helping clients navigate complex legal landscapes and ensuring compliance with all regulations.",
            },
            {
              contentTitle: "Business Location Advisory:",
              content1:
                "We provide strategic advice on selecting the optimal location for your business, considering factors like market access, logistics, and regulatory environment.",
            },
            {
              contentTitle: "Business Match Making Advisory: ",
              content1:
                "Our services include connecting businesses with potential partners, suppliers, and clients to foster growth and collaboration.",
            },
            {
              contentTitle: "Project Finance and Banking Advisory:",
              content1:
                "Our experts guide you through the complexities of project financing, helping secure the necessary funds and banking support.",
            },
            {
              contentTitle: "Subsidies & Incentive:",
              content1:
                "We assist in identifying and obtaining government subsidies and incentives, maximizing financial benefits for your projects.",
            },
            {
              contentTitle: "Environment And Pollution Compliances:",
              content1:
                " We ensure your projects comply with environmental regulations, managing pollution control measures and obtaining necessary clearances. GPCB Clearance - We assist in obtaining clearances from the Gujarat Pollution Control Board (GPCB), ensuring compliance with environmental regulations and facilitating smooth project approvals.",
            },
            {
              contentTitle: "Railway Permissions:",
              content1:
                "Our team facilitates the acquisition of railway permissions required for industrial projects, ensuring seamless integration with rail infrastructure.",
            },
            {
              contentTitle: "Electrical Connection and Load Approval:",
              content1:
                "We manage the process of obtaining electrical connections and load approvals, ensuring timely and efficient service.",
            },
            {
              contentTitle: "Electrical Load Upgradation Permission: ",
              content1:
                "We facilitate the approval process for upgrading electrical loads, ensuring your operations have the necessary power supply.",
            },
            {
              contentTitle: "High Tension Electric Line Permission: ",
              content1:
                "We handle the permissions needed for high tension electric lines, ensuring compliance with safety and regulatory standards.",
            },
            {
              contentTitle: "End To End Industrial Plant Setup Compliance: ",
              content1:
                "We provide comprehensive support for setting up industrial plants, covering all regulatory and compliance requirements from start to finish.",
            },
            {
              contentTitle: "State Highway Permission: ",
              content1:
                "We assist in obtaining permissions for projects that require access to or construction along state highways.",
            },
            {
              contentTitle: "Government Litigation Consultant: ",
              content1:
                "Our legal experts represent and advise clients in government-related litigation, ensuring effective resolution of disputes.",
            },
            {
              contentTitle: "Custom Clearance, Import & Export: ",
              content1:
                "Our services include handling customs clearance and providing support for import and export activities, ensuring compliance with all regulations.",
            },
          ],
        },
      ],
      whyChooseUs: [
        {
          title: "Experience and Expertise:",
          content:
            "Our team brings years of experience in resolving customs clearance issues, international trade, ensuring reliable and efficient services.",
        },
        {
          title: "Customized Solutions:",
          content:
            "We tailor our services to meet the unique needs of each client, providing personalized attention and proactive support.",
        },
      ],

      serviceBottomData: [
        {
          ServiceBottamtitle: "Domestic Regulatory Compliance Services",
          content: [
            {
              content1:
                "Ensuring compliance with federal, state, and local regulations.",
            },
            {
              content1:
                "Assistance with obtaining necessary business licenses and permits.",
            },
            {
              content1:
                "Advisory on industry-specific regulations and standards.",
            },
            {
              content1:
                "Ongoing monitoring and reporting to regulatory authorities.",
            },
            {
              content1:
                "Implementation of internal compliance programs and audits.",
            },
          ],
        },
        {
          ServiceBottamtitle: "International Regulatory Compliance Services",
          content: [
            {
              content1:
                "Advising on compliance with international trade regulations and standards.",
            },
            {
              content1:
                "Assistance with cross-border regulatory filings and documentation.",
            },
            {
              content1:
                "Ensuring compliance with foreign investment regulations.",
            },
            {
              content1:
                "Support in navigating import/export controls and customs regulations.",
            },
            {
              content1:
                "Implementation of global compliance programs and policies.",
            },
          ],
        },
        {
          ServiceBottamtitle: "Specialized Regulatory Compliance Services",
          content: [
            {
              content1:
                "Compliance with environmental regulations and standards.",
            },
            {
              content1: "Advisory on health and safety regulations.",
            },
            {
              content1:
                "Support in complying with industry-specific compliance requirements.",
            },
            {
              content1:
                "Implementation of data protection and privacy regulations.",
            },
            {
              content1:
                "Ensuring compliance with financial and banking regulations.",
            },
          ],
        },
      ],

      content1:
        "We are one of the leading service providers for Workover and Completion Services to various Oil and Gas companies in India. We provide complete rig package with all associated equipment and services to oil and gas industry. We provide wide range of Workover and Completion Rigs ranging from 150 HP to 1000 HP.",
      content2:
        "We also provide end to end solutions to our clients for Drilling and Workover Services. Our Services includes complete rig management, O&M, transportation and logistics services, Accommodation, Catering, Local Logistics support etc.",
      content3:
        "We have remarkably carried out and completed various workover and completion services contract for various Oil and Gas operators. We have a pool of oil and gas professionals having rich and varied experience in onshore and offshore Drilling and workover operations.",

      CapableData: [
        {
          title: "Workover & Completion Services for HPHT wells.",
        },
        {
          title: `Workover and completion Services with fast
       moving skidding rig.`,
        },
        {
          title: "Convention Workover Services.",
        },
        {
          title: "Intelligent Completion Services.",
        },
      ],

      relatedServiceData: [
        {
          imgUrl: exportImport,
          Link: "/services/export-import-advisory-services",
          serviceName: "Export Import Advisory",
        },
        {
          imgUrl: customeDuty,
          Link: "/services/customs-duty",
          serviceName: "Customs Duty",
        },
      ],

      contactUs: [
        {
          title:
            "Ready to optimize your Project Set up strategy and ensure various related compliances? Contact us today to discuss how Abhisa Advisory LLP can support your  needs.",
          // content:
          //   "Contact us today to discuss how Abhisa Advisory LLP can support your  needs.",
        },
      ],
      calltoAction: [
        {
          title:
            "Explore our comprehensive range of services and discover how we can help you achieve  project  efficiency and peace of mind. ",
        },
      ],
    },
    {
      title: "Export-Import advisory",
      metaDescription:
        "Abhisa Advisory LLP offers comprehensive Export-Import Advisory Services including consulting, customs compliance, specialized services, and industry expertise. Contact us to navigate the complexities of international trade and customs regulations.",
      metaKeywords:
        "Export-Import Advisory, Customs Compliance, Trade Consulting, Import Export Regulations, Customs Clearance, Trade Agreements, Preferential Tariff Programs, India",
      ogDescription:
        "We offer comprehensive Export-Import Advisory Services including consulting, customs compliance, specialized services, and industry expertise. Contact us to navigate the complexities of international trade and customs regulations.",
      ogTitle: "Abhisa Advisory LLP - Export-Import Advisory Services",
      clientBenefits: [
        {
          content1:
            "Peace of mind with Customs compliant practices and risk-mitigated operations.",
        },
        {
          content2:
            "Access to expert advice and guidance for complex customs scenarios.",
        },
      ],
      CardButtonsData: [
        "Trade Regulations",
        "Market Research",
        "Logistics Management",
        "Customs Compliance",
        "Risk Assessment",
      ],

      slug: "export-import-advisory-services",
      imgUrl: exportImport,
      innerData: {
        mainHeader: "Regulatory Compliance",
        subHeader:
          "Providing guidance on regulatory compliance requirements for setting up green field projects/services in India and abroad.",
        description: [
          "ABHISA is a renowned leader in the field of regulatory compliance services. With our deep expertise and comprehensive understanding of regulatory frameworks, we assist businesses in navigating the complexities of compliance across various industries. Our dedicated team ensures that your business adheres to all relevant regulations and standards, mitigating risks and promoting sustainable growth.",
        ],
      },
      serviceMiddleData: [
        {
          content: [
            {
              contentTitle: "Import and Export Consulting:",
              content1:
                "Guidance on import/export regulations and trade compliance.",
              content2:
                "Assistance with navigating complex customs procedures.",
              content3:
                "Advice on trade agreements and preferential tariff programs.",
            },
            {
              contentTitle: "Customs Compliance:",
              content1:
                "Ensuring adherence to all applicable customs laws and regulations.",
              content2:
                "Conducting customs audits to identify potential risks and opportunities for improvement.",
              content3:
                "Implementing compliance strategies to mitigate risks and penalties.",
            },
            {
              contentTitle: "Specialized Services:",
              content1:
                "Handling of sensitive or regulated goods (e.g., pharmaceuticals, perishables).",
              content2:
                "Customs clearance for temporary imports/exports and bonded warehousing.",
            },
            {
              contentTitle: "Industry Expertise:",
              content1:
                "Tailored solutions for specific industries (e.g., automotive, electronics, consumer goods).",
              content2:
                "Industry-specific knowledge of customs requirements and challenges.",
              content3:
                "Proactive approach to anticipate industry trends and regulatory changes.",
            },
            {
              contentTitle: "Recent Developments and Updates:",
              content1:
                " Keep the content updated with the latest changes in customs regulations, trade agreements, or geopolitical developments that may impact international trade.",
            },
          ],
        },
      ],
      whyChooseUs: [
        {
          title: "Experience and Expertise:",
          content:
            "Our team brings years of experience in resolving customs clearance issues, international trade, ensuring reliable and efficient services.",
        },
        {
          title: "Customized Solutions:",
          content:
            "We tailor our services to meet the unique needs of each client, providing personalized attention and proactive support.",
        },
      ],

      serviceBottomData: [
        {
          ServiceBottamtitle: "Domestic Regulatory Compliance Services",
          content: [
            {
              content1:
                "Ensuring compliance with federal, state, and local regulations.",
            },
            {
              content1:
                "Assistance with obtaining necessary business licenses and permits.",
            },
            {
              content1:
                "Advisory on industry-specific regulations and standards.",
            },
            {
              content1:
                "Ongoing monitoring and reporting to regulatory authorities.",
            },
            {
              content1:
                "Implementation of internal compliance programs and audits.",
            },
          ],
        },
        {
          ServiceBottamtitle: "International Regulatory Compliance Services",
          content: [
            {
              content1:
                "Advising on compliance with international trade regulations and standards.",
            },
            {
              content1:
                "Assistance with cross-border regulatory filings and documentation.",
            },
            {
              content1:
                "Ensuring compliance with foreign investment regulations.",
            },
            {
              content1:
                "Support in navigating import/export controls and customs regulations.",
            },
            {
              content1:
                "Implementation of global compliance programs and policies.",
            },
          ],
        },
        {
          ServiceBottamtitle: "Specialized Regulatory Compliance Services",
          content: [
            {
              content1:
                "Compliance with environmental regulations and standards.",
            },
            {
              content1: "Advisory on health and safety regulations.",
            },
            {
              content1:
                "Support in complying with industry-specific compliance requirements.",
            },
            {
              content1:
                "Implementation of data protection and privacy regulations.",
            },
            {
              content1:
                "Ensuring compliance with financial and banking regulations.",
            },
          ],
        },
      ],

      content1:
        "We are one of the leading service providers for Workover and Completion Services to various Oil and Gas companies in India. We provide complete rig package with all associated equipment and services to oil and gas industry. We provide wide range of Workover and Completion Rigs ranging from 150 HP to 1000 HP.",
      content2:
        "We also provide end to end solutions to our clients for Drilling and Workover Services. Our Services includes complete rig management, O&M, transportation and logistics services, Accommodation, Catering, Local Logistics support etc.",
      content3:
        "We have remarkably carried out and completed various workover and completion services contract for various Oil and Gas operators. We have a pool of oil and gas professionals having rich and varied experience in onshore and offshore Drilling and workover operations.",

      CapableData: [
        {
          title: "Workover & Completion Services for HPHT wells.",
        },
        {
          title: `Workover and completion Services with fast
       moving skidding rig.`,
        },
        {
          title: "Convention Workover Services.",
        },
        {
          title: "Intelligent Completion Services.",
        },
      ],

      relatedServiceData: [
        {
          imgUrl: customeDuty,
          Link: "/services/customs-duty",
          serviceName: "Customs Duty",
        },
        {
          imgUrl: gstImg,
          Link: "/services/gst-advisory-services",
          serviceName: "GST",
        },
      ],

      contactUs: [
        {
          title:
            "Ready to optimize your customs related issues like held up import Cargos due to issues of mis-classification, adverse sample reports, country of Origin issues, applicability of exemption notification, failure of sample in FSSAI testing, BIS related issues, Hazardous waste issues related to compliance of  Hazardous and other Wastes (Management & Transboundary Movement) Rules, 2016 etc. leading to Show Cause Notices? We give expert advice on cases booked by enforcement agencies like DRI, SIIB of Customs Houses, Customs preventive formations etc.  Contact us today to discuss how we can assist you in navigating the complexities of export import related issues also issue related to DGFT benefits and EXIM policy. ",
          content:
            "Facing challenges such as held-up import cargos due to misclassification, adverse sample reports, country of origin disputes, exemption notification applicability, failed FSSAI testing, BIS-related issues, leading to Show Cause Notices? Our team specializes in providing expert advice on cases booked by enforcement agencies like DRI, SIIB of Customs Houses, and Customs preventive formations.",
          content1:
            "Contact us today to navigate the complexities of export-import issues, including matters related to DGFT benefits and EXIM policy. Let's resolve your customs-related challenges and problems together.",
        },
      ],
      calltoAction: [
        {
          title:
            "Explore our comprehensive customs solutions and discover how Abhisa Advisory LLP can help you achieve seamless customs clearances and compliances.",
        },
      ],
    },
  ];
  const { name } = useParams();

  const filteredData = ServiceInnerPageData.filter(
    (item) => item.slug === name
  );

  const {
    title,
    imgUrl,
    content1,
    innerData,
    serviceBottomData,
    serviceMiddleData,
    whyChooseUs,
    clientBenefits,
    contactUs,
    calltoAction,
    content2,
    content3,
    SectionTitle1,
    SectionTitle1Content,
    SectionTitle2,
    SectionTitle2Content,
    Specification,
    buttonText,
    liText,
    slug,
    capable,
    CapableData,
    CardButtonsData,
    relatedServiceData,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
  } = filteredData[0] ? filteredData[0] : [];
  console.log("meta desc:", metaDescription);
  const descriptionContent = innerData?.description?.join(" ");
  // console.log(descriptionContent, "descriptionContent");

  return (
    <>
      <Fragment>
        {/* <MetaTags>
          <title>Abhisa Advisory | Services</title>
          <meta name="description" content="" />
        </MetaTags> */}

        <Helmet>
          {/* <title>{`Abhisa Advisory LLP - ${title}`}</title> */}
          <title>{ogTitle}</title>
          <meta property="og:title" content={ogTitle ?? ""} />
          <meta name="description" content={metaDescription ?? ""} />
          <meta property="og:description" content={ogDescription ?? ""} />
          <meta property="og:image" content={imgUrl ?? ""} />
          <meta property="og:keywords" content={metaKeywords ?? ""} />
          <meta name="keywords" content={metaKeywords ?? ""} />
          <meta name="robots" content="index, follow" />
          <meta
            property="og:url"
            content={`https://abhisa.in/services/${slug ?? ""}`}
          />
          <link
            rel="canonical"
            href={`https://abhisa.in/services/${slug ?? ""}`}
          />
          <meta property="og:type" content="website" />
          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@AbhisaAdvisory" />
          <meta charset="UTF-8" />
          <meta name="twitter:title" content={ogTitle ?? ""} />
          <meta name="twitter:description" content={ogDescription ?? ""} />
          <meta name="twitter:image" content={imgUrl ?? ""} />

          {/* Twitter Card End */}
        </Helmet>
        <LayoutOne>
          {filteredData && filteredData[0] ? (
            <>
              <SectionHeroBanner title={title} className={"sectionAboutBg"} />
              <ServiceLayout
                CardButtonsData={CardButtonsData}
                imgUrl={imgUrl}
                innerData={innerData}
                content1={content1}
                content2={content2}
                content3={content3}
                SectionTitle1={SectionTitle1}
                SectionTitle1Content={SectionTitle1Content}
                SectionTitle2={SectionTitle2}
                SectionTitle2Content={SectionTitle2Content}
                buttonText={buttonText}
                Specification={Specification}
                liText={liText}
                capable={capable}
                CapableData={CapableData}
                serviceMiddleData={serviceMiddleData}
              />
              <div className="container">
                <SectionCapableAbh
                  CapableData={
                    serviceMiddleData ? serviceMiddleData[0]?.content : []
                  }
                />
              </div>

              {/* <SectionServiceTop data={innerData} /> */}
              <SectionServiceBottom
                sectiondata={serviceBottomData}
                sectiondata2={serviceMiddleData}
                whyChooseUs={whyChooseUs}
                clientBenefits={clientBenefits}
                contactUs={contactUs}
                calltoAction={calltoAction}
              />
              <RelatedService relatedServiceData={relatedServiceData} />
            </>
          ) : (
            <>
              <h1 className="text-center"> No Data Found </h1>
            </>
          )}
        </LayoutOne>
      </Fragment>
    </>
  );
}

export default ServiceInnerPage;
